import React, { Suspense } from "react";
import { Switch } from "react-router-dom";
import { RouteConfig } from "utils/importDynamicModules";
import Loading from "views/common/components/Loading";

// routes config
import routes from "routes";

const TheContent = () => {
  return (
    <main>
      <Suspense fallback={<Loading />}>
        <Switch>{RouteConfig(routes)}</Switch>
      </Suspense>
    </main>
  );
};

export default React.memo(TheContent);
