/* eslint-disable consistent-return */
/* eslint-disable no-use-before-define */
import produce from "immer";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

const store = () => {
  const { constants, reducers, sagas, states: initialState } = importAllStore();
  const { TYPE } = constants;

  return {
    id: TYPE, // Unique id of the module

    // Maps the Store key to the reducer
    reducerMap: {
      [TYPE]: produce(
        (state, { type, payload }) => {
          // always return a new object for the root state
          if (reducers[type]) {
            return reducers[type](state, { payload, type });
          }
        },
        { ...initialState }
      ),
      router: connectRouter(history),
    },

    middlewares: [routerMiddleware(history)],

    sagas: [...sagas],
    // Optional: Any actions to dispatch when the module is loaded
    initialActions: [],
    // Optional: Any actions to dispatch when the module is unloaded
    // finalActions: [],
    retained: true, // Specifies if the module is retained forever in the store
  };
};

export const importAllStore = () => {
  try {
    const actions = require.context("./actions/", true, /.+\.js$/);
    const constants = require.context("./constants/", true, /.+\.js$/);
    let reducers = require.context("./reducers/", true, /.+\.js$/);
    const sagas = require.context("./sagas/", true, /.+\.js$/);
    const states = require.context("./states/", true, /.+\.js$/);
    reducers = reducers.keys().map(reducers);

    if (reducers.length > 1) {
      reducers = reducers.reduce((a, b) => ({ ...a.default, ...b.default }));
    } else {
      reducers = reducers[0].default;
    }

    return {
      actions: actions
        .keys()
        .map(actions)
        .reduce((a, b) => ({ ...a.default, ...b.default })),
      constants: Object.assign({}, ...constants.keys().map(constants)),
      reducers,
      sagas: sagas
        .keys()
        .map(sagas)
        .map((m) => m.default),
      states: Object.assign({}, ...states.keys().map(states)),
    };
  } catch (error) {
    console.warn(error);
  }
};

export default store;
