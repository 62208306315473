import { genActionTypes } from "utils/actionGenerator";

export const TYPE = "root";

export const GET_USER_INFO = genActionTypes(`${TYPE}_GET_USER_INFO`);
export const SET_USER_INFO = `${TYPE}_SET_USER_INFO`;
export const UPDATE_USER_INFO = genActionTypes(`${TYPE}_UPDATE_USER_INFO`);
export const SIGN_IN_WITH_CUSTOM_TOKEN = genActionTypes(
  `${TYPE}_SIGN_IN_WITH_CUSTOM_TOKEN`
);
export const GET_LIST_SEARCH = genActionTypes(`${TYPE}_GET_LIST_SEARCH`);
export const RESET_LIST_SEARCH = `${TYPE}_RESET_LIST_SEARCH`;
export const GET_LINK_GUIDE = genActionTypes(`${TYPE}_GET_LINK_GUIDE`);
export const GET_VOUCHER = genActionTypes(`${TYPE}_GET_VOUCHER`);
export const CLEAR_ERROR_VOUCHER = genActionTypes(
  `${TYPE}_CLEAR_ERROR_VOUCHER`
);
export const CREATE_ORDER = genActionTypes(`${TYPE}_ACTIVATED_VOUCHER_STEP_1`);
export const ACTIVATED_VOUCHER = genActionTypes(
  `${TYPE}_ACTIVATED_VOUCHER_STEP_2`
);

