import React from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import TheContent from "../TheContent";
import "./styles.scss";

const TheLayout = () => {
  const { darkMode } = useSelector((state) => ({
    darkMode: state.root.darkMode,
    user: state.root.user,
  }));

  return (
    <div className={classNames("c-app c-default-layout", darkMode && "c-dark-theme")}>
      <div className="c-wrapper">
        <div className="c-body">
          <TheContent />
        </div>
      </div>
    </div>
  );
};

export default TheLayout;
