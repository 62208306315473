/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-param-reassign */
import axios from "axios";
import { onAuthStateChanged } from "firebase/auth";
import { authFirebase } from "config/firebase";
import apiConfig from "config/apiConfig";

export const getFirebaseToken = async () => {
  const { currentUser } = authFirebase;
  if (currentUser) return currentUser.getIdToken(false);

  // logged in but current user is not fetched --> wait (5s)
  return new Promise((resolve, reject) => {
    const timer = setTimeout(() => {
      reject(null);
    }, 5000);

    const unregisterAuthObserver = onAuthStateChanged(authFirebase, async (user) => {
      if (!user) return reject(null);

      const token = await user.getIdToken(false);
      resolve(token);

      clearTimeout(timer);
      unregisterAuthObserver();
    });
  })
}

const auth = axios.create({
  baseURL: apiConfig.DOMAIN_UTOP_NEWS,
  headers: {
    "content-type": "application/json",
  },
  crossDomain: true,
});

const authWithout = axios.create({
  baseURL: apiConfig.DOMAIN_UTOP_NEWS,
  headers: {
    "content-type": "application/json",
  },
  crossDomain: true,
});

const authPay = axios.create({
  baseURL: apiConfig.DOMAIN_UTOP_APP,
  headers: {
    "content-type": "application/json",
    "api-version": apiConfig.API_VERSION_APP,
    "ocp-apim-subscription-key": apiConfig.OCP_APIM_SUBSCRIPTION_KEY_APP
  },
  crossDomain: true,
});

// update token auth
auth.interceptors.request.use(async (configAuth) => {
  const token = await getFirebaseToken();

  if (token) {
    configAuth.headers.Authorization = `Bearer ${token}`;
  }

  return configAuth;
});

// update token authpay
authPay.interceptors.request.use(async (configAuth) => {
  const token = await getFirebaseToken();

  if (token) {
    configAuth.headers.Authorization = `Bearer ${token}`;
  }

  return configAuth;
});

export const fetchWithToken = (path, method, data, headers, isPay = false) => {
  return new Promise((resolve, reject) => {
    let myData = data;
    if (data && data.file) {
      const formData = new FormData();
      formData.append("imageFile", data.file);
      formData.append("type", "image/jpeg, image/jpg, image/png");
      myData = formData;
    }
    if (isPay) {
      authPay(path, {
        method,
        headers,
        data: myData,
      })
        .then((response) => {
          if (!response) {
            reject("No data from response");
            return;
          }
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    } else {
      auth(path, {
        method,
        headers,
        data: myData,
      })
        .then((response) => {
          if (!response) {
            reject("No data from response");
            return;
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error.response || error);
        });
    }
  });
};

export const fetchWithoutToken = (path, method, data, headers) => {
  return new Promise((resolve, reject) => {
    let myData = data;
    if (data && data.file) {
      const formData = new FormData();
      formData.append("imageFile", data.file);
      formData.append("type", "image/jpeg, image/jpg, image/png");
      myData = formData;
    }
    authWithout(path, {
      method,
      headers,
      data: myData,
    })
      .then((response) => {
        if (!response) {
          reject("No data from response");
          return;
        }
        resolve(response);
      })
      .catch((error) => {
        reject(error.response || error);
      });
  });
};
