/* eslint-disable no-prototype-builtins */
import React from "react";
import loadable from "@loadable/component";
import { DynamicModuleLoader } from "redux-dynamic-modules-react";
import { Route, Redirect } from "react-router-dom";
import Loading from "views/common/components/Loading";

function AuthRoute(props) {
  const token = localStorage.getItem("token");

  if (!token) {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: props.path },
        }}
      />
    );
  }
  return props.children;
}

// Route config
export const RouteConfig = (routes) => {
  return routes.map((route) => {
    if (route._children) {
      return RouteConfig(route._children);
    }

    return (
      <RouteWithSubRoutes
        key={`${route.path}__${route.name}__${route.exact}`}
        {...route}
      />
    );
  });
};

export const RouteWithSubRoutes = (route) => {
  if (route.authority) {
    return (
      <AuthRoute path={route.path}>
        <Route
          path={route.path}
          render={(props) => {
            // If has component
            if (route.hasOwnProperty("component")) {
              return <route.component router={props} />;
            }

            return <Page page={route.page} router={props} />;
          }}
        />
        </AuthRoute>
    );
  }

  return (
    <Route
      path={route.path}
      render={(props) => {
        // If has component
        if (route.hasOwnProperty("component")) {
          return <route.component router={props} />;
        }

        return <Page page={route.page} router={props} />;
      }}
    />
  );
};

// Loading Pages
export const Page = loadable(
  (props) =>
    import(`../views/pages/${props.page}/store`).then((module) => {
      const View = loadable(() => import(`../views/pages/${props.page}`));

      return () => (
        <DynamicModuleLoader modules={[module.default(props)]} strictMode>
            <View {...props} />
        </DynamicModuleLoader>
      );
    }),
  {
    cacheKey: ({ router }) => router.location.pathname,
    fallback: <Loading />,
  }
);
