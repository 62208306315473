const apiConfig = {
  baseURL:
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_BASE_URL
      : process.env.REACT_APP_LOCAL_BASE_URL,
  DOMAIN_UTOP_NEWS: process.env.REACT_APP_DOMAIN_UTOP_NEWS,
  DOMAIN_UTOP_APP: process.env.REACT_APP_DOMAIN_UTOP_APP,
  API_VERSION_APP: process.env.REACT_APP_API_VERSION_APP,
  OCP_APIM_SUBSCRIPTION_KEY_APP: process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY_APP,
};
export default apiConfig;
