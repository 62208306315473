import React from "react";
import loadable from "@loadable/component";
import Loading from "views/common/components/Loading";

const routes = [
  {
    path: "/",
    name: "Home",
    page: "Home",
    exact: true,
    authority: false,
    _tag: "CSidebarNavItem",
    icon: "cil-calendar",
  },
  {
    path: "/listnews",
    name: "ListNews",
    page: "ListNews",
    exact: true,
    authority: false,
    _tag: "CSidebarNavItem",
    icon: "cil-calendar",
  },
  {
    path: "/mylistnews",
    name: "MyListNews",
    page: "MyListNews",
    exact: true,
    authority: true,
    _tag: "CSidebarNavItem",
    icon: "cil-calendar",
  },
  {
    path: "/detail/:id",
    name: "Detail",
    page: "Detail",
    exact: true,
    authority: false,
    _tag: "CSidebarNavItem",
    icon: "cil-calendar",
  },
  {
    path: "/mynewspaper/:id",
    name: "MyNewspaper",
    page: "MyNewspaper",
    exact: true,
    authority: true,
    _tag: "CSidebarNavItem",
    icon: "cil-calendar",
  },
  {
    path: "/orderconfirm",
    name: "OrderConfirm",
    page: "OrderConfirm",
    exact: true,
    authority: true,
    _tag: "CSidebarNavItem",
    icon: "cil-calendar",
  },
  {
    path: "/transactionmethods",
    name: "TransactionMethods",
    page: "TransactionMethods",
    exact: true,
    authority: true,
    _tag: "CSidebarNavItem",
    icon: "cil-calendar",
  },
  {
    path: "/transactionstatus/:id",
    name: "TransactionStatus",
    page: "TransactionStatus",
    exact: true,
    authority: true,
    _tag: "CSidebarNavItem",
    icon: "cil-calendar",
  },
  {
    path: "/login",
    name: "Login",
    page: "Login",
    exact: true,
    authority: false,
    _tag: "CSidebarNavItem",
    icon: "cil-calendar",
  },
  {
    path: "/user/account",
    name: "UserInfo",
    page: "UserInfo",
    exact: true,
    authority: true,
    _tag: "CSidebarNavItem",
    icon: "cil-calendar",
  },
  {
    path: "/user/transactionhistory",
    name: "UserInfo",
    page: "UserInfo",
    exact: true,
    authority: true,
    _tag: "CSidebarNavItem",
    icon: "cil-calendar",
  },
  {
    path: "/user/transactionhistory/detail/:id",
    name: "UserInfo",
    page: "UserInfo",
    exact: true,
    authority: true,
    _tag: "CSidebarNavItem",
    icon: "cil-calendar",
  },
  {
    path: "/readnewspaper/:id",
    name: "ReadNewspaper",
    page: "ReadNewspaper",
    exact: true,
    authority: true,
    _tag: "CSidebarNavItem",
    icon: "cil-calendar",
  },
  {
    path: "/preview/:id",
    name: "ReadNewspaperPreview",
    page: "ReadNewspaperPreview",
    exact: true,
    authority: false,
    _tag: "CSidebarNavItem",
    icon: "cil-calendar",
  },
  {
    component: loadable(() => import("./views/pages/Page404"), {
      cacheKey: () => "404 Not Found",
      fallback: <Loading />,
    }),
  },
];

export default routes;
