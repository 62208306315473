import React from "react";
import "./styles.scss";

function Loading() {
  return (
    <div className="spiner-high-light-container">
      <div className="spiner-high-light" />
    </div>
  );
};

export default Loading;