/* eslint-disable no-param-reassign */
import { addErrors, delErrors } from "utils/ErrorsGenerator";
import * as TYPE from "../constants/constants";

const reducers = {
  [TYPE.GET_USER_INFO.success]: (draft, { payload }) => {
    draft.errors = delErrors(draft.errors, TYPE.GET_USER_INFO.failure);
    draft.userInfo = {
      displayName: payload.displayName,
      email: payload.email,
      phoneNumber: payload.phoneNumber,
      photoURL: payload.photoURL,
      userId: payload.uid,
    };
  },

  [TYPE.GET_USER_INFO.failure]: (draft, { payload }) => {
    draft.errors = addErrors(draft.errors, {
      type: TYPE.GET_USER_INFO.failure,
      msg: payload,
    });
  },

  [TYPE.SET_USER_INFO]: (draft, { payload }) => {
    draft.userInfo = {
      displayName: payload.displayName,
      email: payload.email,
      phoneNumber: payload.phoneNumber,
      photoURL: payload.photoURL,
      userId: payload.uid,
    };
  },

  [TYPE.UPDATE_USER_INFO.success]: (draft, { payload }) => {
    draft.errors = delErrors(draft.errors, TYPE.UPDATE_USER_INFO.failure);
    draft.userInfo = {
      ...draft.userInfo,
      displayName: payload.displayName,
      email: payload.email,
    };
  },
  [TYPE.UPDATE_USER_INFO.failure]: (draft, { payload }) => {
    draft.errors = addErrors(draft.errors, {
      type: TYPE.UPDATE_USER_INFO.failure,
      msg: payload,
    });
  },

  [TYPE.SIGN_IN_WITH_CUSTOM_TOKEN.success]: (draft) => {
    draft.errors = delErrors(
      draft.errors,
      TYPE.SIGN_IN_WITH_CUSTOM_TOKEN.failure
    );
  },

  [TYPE.SIGN_IN_WITH_CUSTOM_TOKEN.failure]: (draft, { payload }) => {
    draft.errors = addErrors(draft.errors, {
      type: TYPE.SIGN_IN_WITH_CUSTOM_TOKEN.failure,
      msg: payload,
    });
  },

  [TYPE.GET_LIST_SEARCH.request]: (draft, { payload }) => {
    draft.errors = delErrors(draft.errors, TYPE.GET_LIST_SEARCH.failure);
    let listNews = [];
    if (payload.skip !== 0) {
      listNews = draft.searchList;
    }
    draft.searchList = listNews;
    draft.fetching = { ...draft.fetching, searchList: true };
  },
  [TYPE.GET_LIST_SEARCH.success]: (draft, { payload }) => {
    draft.searchList = [...draft.searchList, ...payload.value];
    draft.totalCount = payload.count;
    draft.fetching = { ...draft.fetching, searchList: false };
  },
  [TYPE.GET_LIST_SEARCH.failure]: (draft, { payload }) => {
    draft.errors = addErrors(draft.errors, {
      type: TYPE.GET_LIST_SEARCH.failure,
      msg: payload,
    });
    draft.fetching = { ...draft.fetching, searchList: false };
  },

  [TYPE.RESET_LIST_SEARCH]: (draft) => {
    draft.searchList = [];
  },

  [TYPE.GET_LINK_GUIDE.success]: (draft, { payload }) => {
    draft.errors = delErrors(draft.errors, TYPE.GET_LINK_GUIDE.failure);
    draft.linkGuide = payload;
  },
  [TYPE.GET_LINK_GUIDE.failure]: (draft, { payload }) => {
    draft.errors = addErrors(draft.errors, {
      type: TYPE.GET_LINK_GUIDE.failure,
      msg: payload,
    });
  },

  [TYPE.GET_VOUCHER.request]: (draft) => {
    draft.fetching = { ...draft.fetching, voucher: true };
  },

  [TYPE.GET_VOUCHER.success]: (draft, { payload }) => {
    draft.errors = delErrors(draft.errors, TYPE.GET_VOUCHER.failure);
    draft.voucher = payload;
    draft.fetching = { ...draft.fetching, voucher: false };
  },

  [TYPE.GET_VOUCHER.failure]: (draft, { payload }) => {
    draft.errors = addErrors(draft.errors, {
      type: TYPE.GET_VOUCHER.failure,
      msg: payload,
    });
    draft.fetching = { ...draft.fetching, voucher: false };
    draft.voucher = null;
  },
  [TYPE.CLEAR_ERROR_VOUCHER.request]: (draft) => {
    draft.errors = delErrors(draft.errors, TYPE.GET_VOUCHER.failure);
    draft.voucher = null;
  },

  [TYPE.CREATE_ORDER.request]: (draft) => {
    draft.fetching = { ...draft.fetching, activated: true };
  },

  [TYPE.CREATE_ORDER.success]: (draft, { payload }) => {
    draft.errors = delErrors(draft.errors, TYPE.CREATE_ORDER.failure);
    draft.activated = payload.result;
    draft.fetching = { ...draft.fetching, activated: false };
  },

  [TYPE.CREATE_ORDER.failure]: (draft, { payload }) => {
    draft.errors = addErrors(draft.errors, {
      type: TYPE.CREATE_ORDER.failure,
      msg: payload,
    });
    draft.fetching = { ...draft.fetching, activated: false };
    draft.activated = null;
  },
};

export default reducers;
