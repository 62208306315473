import React, { useEffect } from "react";
import TheLayout from "views/layout/TheLayout";
import { useLocation } from "react-router";
import { useDispatch } from "react-redux";
import "./scss/style.scss";

import { authFirebase } from "config/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { LOCAL_STORAGES, PATHNAMES } from "constants/App";

import action from "views/common/store/actions/actions";

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const unregisterAuthObserver = onAuthStateChanged(authFirebase, async (user) => {
      if (!user) return localStorage.removeItem("token");
      const token = await user.getIdToken(false);
      localStorage.setItem("token", token);
      dispatch(action.SET_USER_INFO(user));
    });
    return () => unregisterAuthObserver();
  }, []);

  useEffect(() => {
    if (location.pathname !== PATHNAMES.login) {
      localStorage.setItem(LOCAL_STORAGES.historyUrl, location.pathname);
      localStorage.setItem(LOCAL_STORAGES.historyState, JSON.stringify(location?.state || {}));
    }
  }, [location.pathname]);

  // add Global site tag (gtag.js) - Google Analytics
  useEffect(() => {
    if (process.env.REACT_APP_PROJECT_ID === 'utopqa') return;
    const script = document.createElement('script');
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-N90RK7Y5RJ";
    script.async = true;
    document.head.appendChild(script);
  
    const scriptGA = document.createElement('script');
    const textNode = document.createTextNode(`
      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments); }
      gtag('js', new Date());
      gtag('config', 'G-N90RK7Y5RJ');
    `);

    scriptGA.appendChild(textNode);
    document.head.appendChild(scriptGA);

    return () => {
      document.head.removeChild(script);
      document.head.removeChild(scriptGA);
    }
  }, []);

  return (
    <TheLayout />
  );
};

export default App;
