import { genActions } from "utils/actionGenerator";
import * as TYPE from "../constants/constants";

const actions = {
  GET_USER_INFO: genActions(TYPE.GET_USER_INFO),
  SET_USER_INFO: (payload) => ({ type: TYPE.SET_USER_INFO, payload }),
  UPDATE_USER_INFO: genActions(TYPE.UPDATE_USER_INFO),
  SIGN_IN_WITH_CUSTOM_TOKEN: genActions(TYPE.SIGN_IN_WITH_CUSTOM_TOKEN),
  GET_LIST_SEARCH: genActions(TYPE.GET_LIST_SEARCH),
  RESET_LIST_SEARCH: (payload) => ({ type: TYPE.RESET_LIST_SEARCH, payload }),
  GET_LINK_GUIDE: genActions(TYPE.GET_LINK_GUIDE),
  GET_VOUCHER: genActions(TYPE.GET_VOUCHER),
  CLEAR_ERROR_VOUCHER: genActions(TYPE.CLEAR_ERROR_VOUCHER),
  CREATE_ORDER: genActions(TYPE.CREATE_ORDER),
  ACTIVATED_VOUCHER: genActions(TYPE.ACTIVATED_VOUCHER),
};
export default actions;

