import { encodeQueryData } from "utils/stringFormat";
import { fetchWithToken, fetchWithoutToken } from "services/request";
import * as TYPE from "../store/constants/constants";

const api = {
  [TYPE.UPDATE_USER_INFO.request]: async (data) => {
    return fetchWithToken(
      `personal/UpdateUserProfile`,
      "PUT",
      data,
      undefined,
      true
    );
  },
  [TYPE.GET_LIST_SEARCH.request]: async ({ skip, take, q, userId }) => {
    const search = encodeQueryData({ skip, take, q });
    const headers = userId ? { userId } : {};
    return fetchWithoutToken(`NewspaperWeb/search${search}`, "GET", undefined, {
      ...headers,
    });
  },
  [TYPE.GET_LINK_GUIDE.request]: async () => {
    return fetchWithoutToken(`NewspaperWeb/GuideWeb`, "GET");
  },
  [TYPE.GET_VOUCHER.request]: async ({ voucherCode, userId }) => {
    const headers = userId ? { userId } : {};
    const param = encodeQueryData({ voucherCode });
    return fetchWithToken(
      `Product/GetDetailVoucher${param}`,
      "GET",
      undefined,
      { ...headers }
    );
  },
  [TYPE.CREATE_ORDER.request]: async ({ merchantId, userId, payload }) => {
    const headers = userId ? { userId } : {};
    return fetchWithToken(
      `OrderWeb`,
      "POST",
      { ...payload, merchantId, userId },
      { ...headers }
    );
  },

  [TYPE.ACTIVATED_VOUCHER.request]: async ({ userId, payload }) => {
    const headers = userId ? { userId } : {};

    return fetchWithToken(`OrderWeb/ActivatedVoucher`, "POST", payload, {
      ...headers,
    });
  },
};
export default api;

