import { call, put, takeLatest, fork } from "redux-saga/effects";
import { getUserProfile, getsignInWithCustomToken } from "config/firebase";
import actions from "../actions/actions";
import * as TYPE from "../constants/constants";
import api from "../../api/api";

const sagas = {
  *[TYPE.GET_USER_INFO.request]() {
    try {
      const data = yield call(getUserProfile);
      if (data) {
        yield put(actions.GET_USER_INFO.success(data));
      } else {
        yield put(actions.GET_USER_INFO.failure(data));
      }
    } catch (error) {
      yield put(actions.GET_USER_INFO.failure(error));
    }
  },
  *[TYPE.UPDATE_USER_INFO.request](action) {
    try {
      const data = yield call(
        api[TYPE.UPDATE_USER_INFO.request],
        action.payload
      );
      if (data.status === 204) {
        yield put(actions.UPDATE_USER_INFO.success(action.payload));
      } else {
        yield put(actions.UPDATE_USER_INFO.failure(data?.data));
      }
    } catch (error) {
      yield put(actions.UPDATE_USER_INFO.failure(error?.data));
    }
  },
  *[TYPE.SIGN_IN_WITH_CUSTOM_TOKEN.request]({ payload }) {
    try {
      const data = yield call(getsignInWithCustomToken, payload);
      if (data) {
        yield put(actions.SIGN_IN_WITH_CUSTOM_TOKEN.success(data));
      } else {
        yield put(actions.SIGN_IN_WITH_CUSTOM_TOKEN.failure(data));
      }
    } catch (error) {
      yield put(actions.SIGN_IN_WITH_CUSTOM_TOKEN.failure(error));
    }
  },
  *[TYPE.GET_LIST_SEARCH.request](action) {
    try {
      const data = yield call(
        api[TYPE.GET_LIST_SEARCH.request],
        action.payload
      );
      if (data) {
        yield put(actions.GET_LIST_SEARCH.success(data.data));
      } else {
        yield put(actions.GET_LIST_SEARCH.failure(data.data));
      }
    } catch (error) {
      yield put(actions.GET_LIST_SEARCH.failure(error));
    }
  },
  *[TYPE.GET_LINK_GUIDE.request](action) {
    try {
      const data = yield call(api[TYPE.GET_LINK_GUIDE.request], action.payload);
      if (data) {
        yield put(actions.GET_LINK_GUIDE.success(data.data));
      } else {
        yield put(actions.GET_LINK_GUIDE.failure(data));
      }
    } catch (error) {
      yield put(actions.GET_LINK_GUIDE.failure(error));
    }
  },
  *[TYPE.GET_VOUCHER.request](action) {
    try {
      const data = yield call(api[TYPE.GET_VOUCHER.request], action.payload);
      if (data) {
        console.log("error1", data);
        yield put(actions.GET_VOUCHER.success(data.data));
      } else {
        console.log("error1", data);
        yield put(actions.GET_VOUCHER.failure(data));
      }
    } catch (error) {
      console.log("error2", error);
      yield put(actions.GET_VOUCHER.failure(error));
    }
  },

  *[TYPE.CREATE_ORDER.request](action) {
    try {
      const rsActivatedVoucher = yield call(
        api[TYPE.ACTIVATED_VOUCHER.request],
        {
          userId: action.payload.userId,
          payload: {
            voucherCode: action?.payload?.payload.voucherCode,
          },
        }
      );
      if (rsActivatedVoucher && rsActivatedVoucher.status === 200) {
        const rsOrder = yield call(
          api[TYPE.CREATE_ORDER.request],
          action.payload
        );
        if (rsOrder) {
          yield put(actions.CREATE_ORDER.success(rsOrder.data));
          action.payload.setIsShow(false);
          window.location.replace("/mylistnews");
        } else {
          yield put(actions.CREATE_ORDER.failure(rsOrder));
        }
      } else {
        yield put(actions.CREATE_ORDER.failure(rsActivatedVoucher));
      }
    } catch (error) {
      yield put(actions.CREATE_ORDER.failure(error));
    }
  },
};

function* watchedSaga() {
  yield takeLatest(
    TYPE.GET_USER_INFO.request,
    sagas[TYPE.GET_USER_INFO.request]
  );
  yield takeLatest(
    TYPE.UPDATE_USER_INFO.request,
    sagas[TYPE.UPDATE_USER_INFO.request]
  );
  yield takeLatest(
    TYPE.SIGN_IN_WITH_CUSTOM_TOKEN.request,
    sagas[TYPE.SIGN_IN_WITH_CUSTOM_TOKEN.request]
  );
  yield takeLatest(
    TYPE.GET_LIST_SEARCH.request,
    sagas[TYPE.GET_LIST_SEARCH.request]
  );
  yield takeLatest(
    TYPE.GET_LINK_GUIDE.request,
    sagas[TYPE.GET_LINK_GUIDE.request]
  );
  yield takeLatest(TYPE.GET_VOUCHER.request, sagas[TYPE.GET_VOUCHER.request]);
  yield takeLatest(TYPE.CREATE_ORDER.request, sagas[TYPE.CREATE_ORDER.request]);
}

export default function* rootChild() {
  yield fork(watchedSaga);
}

