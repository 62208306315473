/* eslint-disable no-cond-assign */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */

export const encodeQueryData = (data) => {
  const values = [];
  for (const d in data) {
    if (data[d] === null || data[d] === undefined) continue;
    values.push(`${encodeURIComponent(d)}=${encodeURIComponent(data[d])}`);
  }
  return `?${values.join("&")}`;
};

export function getJsonFromUrl(url) {
  if (!url) return {};
  const query = url.substr(1);
  const result = {};
  query.split("&").forEach((part) => {
    const item = part.split("=");
    result[item[0]] = decodeURIComponent(item[1]);
  });
  return result;
}

/**
 * Transfer a random string (Vietnamese/English) to a standardized string for searching
 * @param str string to be convert to standard type for easier searching
 * @returns A normalized string, without special character of Vietnamese, and in lowercase
 */
export const standardizeStr = (str) => {
  return str
    .normalize("NFD") // chuyển về dạng tổ hợp
    .replace(/[\u0300-\u036f]/g, "") // xóa các ký tự dấu tổ hợp
    .replace(/[đĐ]/g, (m) => (m === "đ" ? "d" : "D")) // chuyển chữ đ/Đ thành d/D
    .toLowerCase();
}

export const isString = (str) => {
  if (!str || typeof str !== "string" || str.length === 0) return false;
  return true;
}

export const getDeliveryListStatus = (status, isPayByCash) => {
  let myStatus = status;
  if (!isPayByCash && status === 1) myStatus = 4;
  switch (myStatus) {
    case 1:
      return { title: "Đặt hàng thành công", color: "#0091FF" };
    case 2:
      return { title: "Hoàn thành", color: "#6DD400" };
    case 3:
      return { title: "Đã huỷ", color: "#FD0000" };
    case 4:
      return { title: "Chưa thanh toán", color: "#0091FF" };
    default:
      return { title: "", color: "#151515" };
  }
};

export const getDeviceId = () => {
  const ua = navigator.userAgent.toLowerCase();
  let s;
  if (s = ua.match(/msie ([\d.]+)/)) return s[1];
  if (s = ua.match(/firefox\/([\d.]+)/)) return s[1];
  if (s = ua.match(/chrome\/([\d.]+)/)) return s[1];
  if (s = ua.match(/opera.([\d.]+)/)) return s[1];
  if (s = ua.match(/version\/([\d.]+).*safari/)) return s[1];
  return 0;

}