export const GOOGLEAPIS_MAP = {
  domain: "https://maps.googleapis.com/maps/api/geocode/json",
  key: "AIzaSyB2qCpwjJNo3SOshQGB5EiDr_jrEo18dwY"
}

export const DOWNLOAD_APP = "https://s.utop.vn/utopnews";

// eslint-disable-next-line no-useless-escape
export const IS_EXIST_SPECIAL_CHAR_REGEX = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

export const LOCAL_STORAGES = {
  myInfo: "myInfo",
  linkShare: "linkShare",
  historyUrl: "historyUrl",
  historyState: "historyState",
  bookmark: "bookmark",
}

export const PATHNAMES = {
  listnews: "/listnews",
  login: "/login",
  orderconfirm: "/orderconfirm",
  transactionstatus: "/transactionstatus",
  transactionmethods: "/transactionmethods",
  miniGame: "https://iomgame.com/dumbbird/",
  transactionhistory: "/user/transactionhistory"
}

export const PAYMENT_METHODS = {
  momo: "Momo",
  cod: "COD",
  vnpay: "VNPAY",
  foxpay: "Foxpay"
}

export const PAYMENT_HIDDEN = ["Payoo", "Utop"];
export const PAYMENT_OPTION_ONDEMAND = ["Momo", "VnPay", "Foxpay", "Payoo", "creditcard"] 
export const PAYMENT_ONE_CLICK = ["Utop", "JCB", "VISA", "creditcard", "COD"];
export const PAYMENT_METHODS_OTHER = ["Payoo", "tpbank", "Momo", "VnPay", "Foxpay"];
export const PAYMENT_ERRORS = ["TransactionMethods_CREATE_PAYMENT_FAILURE", "TransactionMethods_TRANSACTION_PAY_FAILURE"];

export const USER_TABS = {
  account: "/user/account",
  transactionhistory: "/user/transactionhistory",
  transactiondetail: "/user/transactionhistory/detail/:id",
}

export const USER_TABS_OPTIONS = [
  {
    id: 1,
    icon: "UserGray",
    pathname: USER_TABS.account,
    name: "Thông tin tài khoản"
  },
  {
    id: 2,
    icon: "ListGray",
    pathname: USER_TABS.transactionhistory,
    name: "Lịch sử giao dịch"
  }
]

export const BREADCRUMBS_OPTIONS = [
  {
    id: "home",
    label: "Hone",
    path: "/detail/:id"
  },
  {
    id: "orderconfirm",
    label: "Xác nhận thông tin",
    path: "/orderconfirm"
  },
  {
    id: "transactionmethods",
    label: "Chọn hình thức thanh toán",
    path: "/transactionmethods"
  }
]